/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ControlTariff } from '@/store/tariff/types';

const namespace: string = 'tariff';

export default class VtcTariffChoicePage extends Vue {
	@State('tariff') tariff: ControlTariff | undefined;
	@Action('fetchVtcTariff', {namespace}) fetchVtcTariff: any;
	@Action('storeVtcTariff', {namespace}) storeVtcTariff: any;
	@Getter('getVtcTariff', {namespace}) getVtcTariff: any;

	selectTariff(card: any) {
		this.storeVtcTariff(card);
	}

	mounted() {
		this.fetchVtcTariff();
	}
}
